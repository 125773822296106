import React from 'react'
import { graphql } from 'gatsby'

//Component
import Layout from '../components/Layout'
import CardList from '../components/CardList'
import SEO from '../components/SEO'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'
import PageTitle from '../components/PageTitle'
import PageResponsiveTitle from '../components/PageResponsiveTitle'
import ContainerGrid from '../components/ContainerGrid'

const Works = ({ data }) => {
  const posts = data.allContentfulWork.edges
  return (
    <Layout>
      <Helmet>
        <title>{`${config.siteTitle}`}</title>
      </Helmet>
      <SEO />
      <ContainerGrid>

        <PageTitle>Work.</PageTitle>
        <PageResponsiveTitle>Work.</PageResponsiveTitle>

        <CardList
            posts={posts}
            firstHeroImage={false}
        />
      </ContainerGrid>
    </Layout>
  )
}

export const query = graphql`
  query {
    allContentfulWork(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          id
          featuredHeader
          slug
          publishDate(formatString: "MMMM DD, YYYY")
          heroImage {
            title
            fluid(maxWidth: 2200) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          body {
            childMarkdownRemark {
              html
              excerpt(pruneLength: 80)
            }
          }
        }
      }
    }
  }
`
export default Works
